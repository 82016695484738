import React from "react";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Button from "Shared/Button";
import { Flex } from "Shared/flexbox";
import type { SupportedLocale } from "types";

type ErrorImageProps = {
  locale: SupportedLocale;
};

const ErrorImage = styled.div<ErrorImageProps>`
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: ${({ locale }) =>
    `url(${process.env.PUBLIC_URL}/images/error_${locale}.webp)`};
`;

type ErrorProps = {
  hideDashboardLink?: boolean;
}

const Error: React.FC<ErrorProps> = ({ hideDashboardLink = false }) => {
  const { i18n, t } = useTranslation();
  const history = useHistory();

  const refresh = () => history.go(0);

  const goToDashboard = () => {
    history.push("/dashboard");
    history.go(0);
  };

  return (
    <Flex width="100%" height="100%">
      <Flex
        my="auto"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <ErrorImage locale={i18n.language as SupportedLocale} />

        <Flex alignItems="center" margin="auto" flexDirection="column">
          <p>
            <b>{t("shared.what-do-you-want-to-do-next")}</b>
          </p>
          <Button
            variant="light"
            fontSize="large"
            icon="refresh-sync"
            label={t("shared.update")}
            onClick={refresh}
          />
          {hideDashboardLink ? null:  <Button
            variant="light"
            fontSize="large"
            icon="dashboard"
            label={t("shared.go-to-item", { itemName: "Dashboard" })}
            onClick={goToDashboard}
          />}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Error;
